import React from 'react';
import PlanItem from '../PlanItem';

const PlanItemList = ({ data, type, planName }) => (
  <div className="itemList" style={{ width: '100%', marginTop: 32 }}>
    {/* recommended items first */}
    {
      data
      .filter(item => item.type === type)
      .filter(item => item.required.includes(planName))
      .map(item => (
        <PlanItem
          key={item.name}
          name={item.name}
          img={item.img}
          description={item.description}
          notes={item.notes}
          resources={item.resources}
          recommended
        />
      ))
    }
    {/* optional items  */}
    {
      data
      .filter(item => item.type === type)
      .filter(item => item.optional.includes(planName))
      .map(item => (
        <PlanItem
          key={item.name}
          name={item.name}
          img={item.img}
          description={item.description}
          notes={item.notes}
          resources={item.resources}
          optional
        />
      ))
    }
  </div>
);

export default PlanItemList;
