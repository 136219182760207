import React from 'react';
import PropTypes from 'prop-types';
import { at } from 'lodash';
import { useField, useFormikContext } from 'formik';
import {
  FormLabel,
  FormControl,
  FormGroup,
  FormControlLabel,
  FormHelperText,
  Checkbox
} from '@material-ui/core';

const CheckboxField = props => {
  const { setFieldValue } = useFormikContext();
  const { label, data, ...rest } = props;
  const [field, meta] = useField(props);
  const { name, value } = field;
  const [touched, error] = at(meta, 'touched', 'error');
  const isError = touched && error && true;

  const handleChange = e => {
    setFieldValue(name, value.includes(e.target.value) ? value.filter(item => item !== e.target.value) : [...value, e.target.value]);
  };
  
  const _renderHelperText = () => {
    if (isError) {
      return <FormHelperText>{error}</FormHelperText>
    } 
  };

  return (
    <FormControl {...rest} error={isError}>
      <FormLabel component="legend" style={{ marginBottom: 8 }}>{label}</FormLabel>
      <FormGroup {...field}>
        {
          data.map(item => (
            <FormControlLabel
              key={item.value}
              control={<Checkbox checked={value.includes(item.value)} onChange={handleChange} name={item.value} value={item.value} />}
              label={item.label}
            />
          ))
        }
      </FormGroup>
      {_renderHelperText()}
    </FormControl>
  );
};

CheckboxField.propTypes = {
  data: PropTypes.array.isRequired
};

export default CheckboxField;
