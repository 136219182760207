import { makeStyles } from '@material-ui/core/styles';
export default makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.primary,
  },
  tab: {
    minWidth: '0'
  },
  tabs: {
    '& > div > div': {
      flexDirection: 'column'
    },
    display: 'none'
  },
  tabScroll: {
    display: 'flex'
  },
  [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
    tabs: {
      display: 'flex',
      '& > div > div': {
        flexDirection: 'row'
      }
    },
    tabScroll: {
      display: 'none'
    }
  },
  mobileSelector: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    '& > button': {
      background: 'none',
      border: 'none',
      outline: 'none',
      padding: 0,
      '&:hover': {
        cursor: 'pointer'
      }
    },
    '& > button > svg': {
      color: theme.palette.primary.main,
      height: 48,
      width: 48
    },
    '& > button:nth-child(1) > svg': {
      transform: 'rotate(180deg)'
    },
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      display: 'none'
    }
  },
  selectedSection: {
    padding: `${theme.spacing(1)}px ${theme.spacing(4)}px`,
    margin: `${theme.spacing(1)}px`,
    backgroundColor: theme.palette.primary.main,
    border: 'none',
    borderRadius: theme.shape.borderRadius,
    color: 'white',
    width: 'calc(100% - 240px)',
    minWidth: 218,
    textAlign: 'center',
    '& > p': {
      fontWeight: 600,
      fontSize: 20,
      [theme.breakpoints.down(360 + theme.spacing(2) * 2)]: {
        fontSize: 16
      }
    },
    [theme.breakpoints.down(360 + theme.spacing(2) * 2)]: {
      padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
      minWidth: 154
    }
  },
  buttonArrowDisabled: {
    opacity: .2,
    '&:hover': {
      cursor: 'default !important'
    }
  },
  // item stuff
  item: {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.grey[700],
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    '&:first-child': {
      marginTop: 0
    }
  },
  photoOuter: {
    width: 160,
    height: 120,
    marginRight: theme.spacing(2),
    overflow: 'hidden',
    display: 'none',
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    '& > img': {
      maxWidth: 200
    }
  },
  accordionOuter: {
    flexGrow: 1,
    flexShrink: 50
  },  
  accordion: {
    marginTop: '0 !important'
  },
  accordionSummary: {
    borderTopLeftRadius: theme.shape.borderRadius,
    borderTopRightRadius: theme.shape.borderRadius,
    borderBottomLeftRadius: theme.shape.borderRadius,
    borderBottomRightRadius: theme.shape.borderRadius
  },
  accordionTop: {
    width: '100%',
    minHeight: 120 - 24,
    padding: '0px 8px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    '& > h4': {
      fontWeight: theme.typography.fontWeightBold
    }
  },
  itemDescription: {
    fontSize: 18
  },  
  recommendedFlag: {
    position: 'absolute',
    top: 0,
    right: 0,
    backgroundColor: theme.palette.secondary[900],
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '4px 8px',
    fontWeight: theme.typography.fontWeightBold,
    fontSize: "1rem",
    borderTopRightRadius: theme.shape.borderRadius
  },
  optionalFlag: {
    position: 'absolute',
    top: 0,
    right: 0,
    backgroundColor: theme.palette.info.main,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '4px 8px',
    fontWeight: theme.typography.fontWeightBold,
    fontSize: "1rem",
    borderTopRightRadius: theme.shape.borderRadius
  },
  accordionBottom: {
    padding: '0px 8px',
    '& > ul': {
      margin: 0,
      marginLeft: theme.spacing(1),
      listStylePosition: 'inside',
      marginTop: -12,
      padding: 0
    },
    '& > ul > li': {
      fontSize: 18
    }
  },
  resourcesAndVendors: {
    '& > h5': {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(1)
    }
  },
  resource: {
    fontSize: 18,
    '& a': {
      color: theme.palette.primary.main,
    }
  }
}));