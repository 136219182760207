import formModel from './formModel';
const {
  formField: {
    streetAddress,
    country,
    zipCode,
    price,
    bedrooms,
    bathrooms,
    isFurnished,
    luxuryFactor,
    propertyHighlights,
    agentObjectives
  }
} = formModel;

const formInitialValues = {
  [streetAddress.name]: '',
  [country.name]: '',
  [zipCode.name]: '',
  [price.name]: '',
  [bedrooms.name]: '',
  [bathrooms.name]: '',
  [isFurnished.name]: '',
  [luxuryFactor.name]: 0,
  [propertyHighlights.name]: [],
  [agentObjectives.name]: []
};

export default formInitialValues;
