import React from 'react';
import {
  BrowserRouter as Router,
  Route 
} from 'react-router-dom';
import Layout from './components/Layout';
import MainPage from './components/MainPage';
import Results from './components/Results';
import './App.sass';

function App() {
  return (
    <Router history={History}>
      <div>
        <Route exact path="/" component={() => <Layout><MainPage /></Layout>} />
        <Route path="/plan" component={() => <Layout wide><Results /></Layout>} />
      </div>
    </Router>
  );
}

export default App;
