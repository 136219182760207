import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import logo from './images/logo-m.png';
import luxvtLogo from './images/logo-white.png';
import useStyles from './styles';

const Header = () => {
  const classes = useStyles();
  const theme = useTheme();

  console.log('THEME:', theme);
  return (
    <AppBar position="absolute" color="default" className={classes.appBar}>
      <Toolbar className={classes.toolBar}>
        <img className={classes.logo} src={logo} alt="logo" />
        <div className={classes.headerSection}>
          <Typography variant="h4" className={classes.title}>Listing Planner</Typography>
          <Typography variant="h6" className={classes.subTitle}>POWERED BY <img src={luxvtLogo} alt="luxvt logo" /></Typography>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
