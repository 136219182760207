import React, { useState } from 'react';
import commaNumber from 'comma-number';
import { at } from 'lodash';
import { useField } from 'formik';
import { TextField } from '@material-ui/core';

const NumberFormatField = props => {
  const { errorText, ...rest } = props;
  const [field, meta, helpers] = useField(props);

  const [val, setVal] = useState('');

  function _renderHelperText() {
    const [touched, error] = at(meta, 'touched', 'error');
    if (touched && error) {
      return error;
    }
  }

  return (
    <TextField
      type="text"
      error={meta.touched && meta.error && true}
      helperText={_renderHelperText()}
      {...field}
      {...rest}
      value={commaNumber(val.split(',').join(''))}
      onChange={e => {
        const { value } = e.target;
        setVal(value);
        helpers.setValue(e.target.value.split(',').join(''));
      }}
    />
  );
};

export default NumberFormatField;
