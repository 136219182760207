import React from 'react';
import PlanItem from '../PlanItem';

const TipItemList = ({
  data,
  type,
  planName,
  ...rest
}) => (
  <div className="itemList" style={{ width: '100%', marginTop: 32 }}>
    {
      data
      .filter(item => rest[item.booleanValueMap] === 'true')
      .map(item => (
        <PlanItem
          key={item.name}
          name={item.booleanValueTitle}
          img={item.img}
          description={item.booleanValueDescription}
          notes={item.notes}
          resources={item.resources}
          title={item.name}
          isTip
        />
      ))
    }
  </div>
);

export default TipItemList;

