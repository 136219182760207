import React from 'react';
import PropTypes from 'prop-types';
import { at } from 'lodash';
import { useField } from 'formik';
import {
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  FormHelperText
} from '@material-ui/core';

const SelectField = props => {
  const { label, data, ...rest } = props;
  const [field, meta] = useField(props);
  const { value: selectedValue } = field;
  const [touched, error] = at(meta, 'touched', 'error');
  const isError = touched && error && true;

  const _renderHelperText = () => {
    if (isError) {
      if (isError) {
        return <FormHelperText>{error}</FormHelperText>
      }
    } 
  };

  return (
    <FormControl {...rest} error={isError}>
      <InputLabel>{label}</InputLabel>
      {/* Keep native select code here in case someone complains about dropdown positioning issue */}
      {/* <Select 
        native
        {...field} 
        value={selectedValue || selectedValue === false ? selectedValue : ''}
      >
        <option value={null}></option>
        {
          data.map((item, index) => (
            <option key={index} value={item.value}>
              {item.label}
            </option>
          ))
        }
      </Select> */}
      <Select 
        {...field} 
        value={selectedValue || selectedValue === false ? selectedValue : ''}
      >
        {
          data.map((item, index) => (
            <MenuItem key={index} value={item.value}>
              {item.label}
            </MenuItem>
          ))
        }
      </Select>
      {_renderHelperText()}
    </FormControl>
  );
};

SelectField.propTypes = {
  data: PropTypes.array.isRequired
};

export default SelectField;
