import {
  createMuiTheme,
  responsiveFontSizes,
  makeStyles
} from '@material-ui/core/styles';

let theme = createMuiTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#1E355E',
    },
    secondary: {
      main: '#d50f16'
    }
  },
  typography: {
    fontFamily: [
      'Montserrat',
      '-apple-system',
      'BlinkMacSystemFont',
      'Arial',
      'sans-serif'
    ],
    body1: {
      lineHeight: 1.2
    }
  }
});
theme = responsiveFontSizes(theme);

const useStyle = wide => makeStyles(() => {
  const width = wide ? 1200 : 600;
  return {
    root: {
      width: 'auto',
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      [theme.breakpoints.up(width + theme.spacing(2) * 2)]: {
        width,
        marginLeft: 'auto',
        marginRight: 'auto'
      },
      color: theme.palette.primary.contrastText
    },
    paper: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      padding: theme.spacing(2),
      [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
        marginTop: theme.spacing(6),
        marginBottom: theme.spacing(6),
        padding: theme.spacing(3)
      }
    }
  }
});

export { theme, useStyle };