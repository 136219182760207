import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  appBar: {
    position: 'relative',
    backgroundColor: theme.palette.primary.main
  },
  toolBar: {
    padding: `${theme.spacing(3)}px ${theme.spacing(3)}px`,
    [theme.breakpoints.down(540)]: {
      padding: `${theme.spacing(2)}px ${theme.spacing(2)}px`
    }
  },
  logo: {
    height: 48,
    [theme.breakpoints.down(540)]: {
      height: 40
    },
  },
  headerSection: {
    marginLeft: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    color: 'white',
    [theme.breakpoints.down(360 + theme.spacing(2) * 2)]: {
      display: 'none'
    }
  },
  title: {
    fontWeight: 400,
    marginBottom: 0,
    lineHeight: 1,
    fontSize: 22,
    [theme.breakpoints.down(540)]: {
      fontSize: 18
    }
  },
  subTitle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
    fontWeight: 300,
    marginTop: 6,
    fontSize: 15,
    lineHeight: 1,
    whiteSpace: 'nowrap',
    [theme.breakpoints.down(540)]: {
      fontSize: 14
    },
    '& > img': {
      height: 16,
      paddingBottom: 2,
      marginLeft: 6,
      [theme.breakpoints.down(540)]: {
        height: 16
      },
    }
  }
}));
