import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  loading: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    backgroundColor: 'rgba(0, 0, 0, .7)',
    zIndex: 1200,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  loadingText: {
    color: 'white'
  }
}));
