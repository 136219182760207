import proPhotography from './images/pro-photography.jpeg';
import propertyVideo from './images/property-video.jpg';
import threeDTour from './images/3d-tour.jpg';
import homeStaging from './images/home-staging.jpeg';
import droneAerial from './images/drone-aerial.jpeg';
import dayToDusk from './images/day-to-dusk.jpg';
import virtualStaging from './images/vs.jpg';
import floorPlan from './images/floor-plan.jpg';
import luxAdvertising from './images/lux-advertising.jpg';
import globalAdvertising from './images/global-advertising.jpeg';
import socialMediaPromotion from './images/social-media-promotion.png';
import openHouse from './images/open-house.jpg';
import listingSyndication from './images/listing-syndication.jpg';
import localAdvertising from './images/local-advertising.png';
import postcardMailer from './images/postcard-mailer.png';
import brochuresCatalogs from './images/brochures-catalogs.jpg';
import hardcoverBooks from './images/hardcover-books.jpg';
import listingWebsite from './images/listing-website.png';
import virtualCatalog from './images/virtual-catalog.jpg';
import mediaKit from './images/media-kit.png';
import activityReport from './images/activity-report.jpg';
import signage from './images/signage.jpg';
// tips for you icons
import LandscapeIcon from '@material-ui/icons/Landscape';
import HdrOnIcon from '@material-ui/icons/HdrOn';
import WavesIcon from '@material-ui/icons/Waves';
import ApartmentIcon from '@material-ui/icons/Apartment';
import KingBedOutlinedIcon from '@material-ui/icons/KingBedOutlined';
import BorderAllOutlinedIcon from '@material-ui/icons/BorderAllOutlined';
import DirectionsWalkIcon from '@material-ui/icons/DirectionsWalk';
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects';
import BuildIcon from '@material-ui/icons/Build';
import BorderClearIcon from '@material-ui/icons/BorderClear';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import EmojiEmotionsIcon from '@material-ui/icons/EmojiEmotions';
import VideocamIcon from '@material-ui/icons/Videocam';

export const VISUALS = 'Visuals';
export const ADVERTISING = 'Advertising';
export const MATERIALS = 'Materials';
export const TIPS_FOR_YOU = 'Tip For You';

export const RECOMMENDED = 'Recommended';
export const OPTIONAL = 'Optional';

export const UPPER_PRICE_TIER = 2000000;
export const MIDDLE_PRICE_TIER = 1000000;

// hardcoding plan names. L, S and B refer to the plan ("lean", "satisfactory", "boost"), while 
// the numbers 1, 2 and 3 refer the the price tier, so L3 represents lean plan highest price tier
const L1 = 'L1';
const L2 = 'L2';
const L3 = 'L3';

const S1 = 'S1';
const S2 = 'S2';
const S3 = 'S3';

const B1 = 'B1';
const B2 = 'B2';
const B3 = 'B3';

export const LEAN = 'Lean';
export const SATISFACTORY = 'Satisfactory';
export const BOOST = 'Boost';

export const plans = {
  [LEAN]: [L1, L2, L3],
  [SATISFACTORY]: [S1, S2, S3],
  [BOOST]: [B1, B2, B3]
};

export const UPPER_LUXURY_TIER = 2;

export const data = [
  {
    name: 'Pro Photography',
    type: VISUALS,
    required: [L1, L2, L3, S1, S2, S3, B1, B2, B3],
    optional: [],
    img: proPhotography,
    description: '',
    notes: [
      'Top quality professional photos are the cornerstone of luxury real estate marketing.',
      'Many photographers can provide additional services such as video, 3D/VR tours and drone.',
      'Digital image enhancement can save money on traditional needs like staging and twilight photography.'
    ],
    resources: [
      {
        linkName: 'Luxury Photographer & Video Directory',
        url: 'https://elite.luxvt.com/real-estate-photography/',
        description: 'to help you find a great pro in your area.'
      },
      {
        linkName: 'Real Estate Tips: Luxury Real Estate Photography',
        url: 'https://elite.luxvt.com/real-estate-photography-tips/'
      },
      {
        linkName: 'Real Estate Photography: The Ultimate Guide for Agents Who Know Image Matters',
        url: 'https://www.followupboss.com/blog/real-estate-photography'
      },
      {
        linkName: 'Zillow Photographer Resource',
        url: 'https://www.zillow.com/agent-finder/photographer-reviews/'
      }
    ]
  },
  {
    name: 'Property Video',
    booleanValueMap: 'smartAmenities',
    booleanValueTitle: 'Smart Amenities',
    booleanValueDescription: () => <>Because you selected <span>Smart Amenities</span>, I’d suggest a <span>Property Video</span> to show off those high tech features.</>,
    type: VISUALS,
    required: [L1, L2, L3, S1, S2, S3, B1, B2, B3],
    optional: [],
    img: propertyVideo,
    description: '',
    notes: [
      'Video is essential to your luxury listing, and there are many formats to choose from.',
      'The simplest and most cost effective option is to use still photos animated into a sleek video format.',
      'At the high end of the spectrum, Lifestyle Videos may include professional actors.',
      'Video is an effective way to show off smart home features and amenities.',
      'Drones can provide breathtaking aerial footage to make your video shine.'
    ],
    resources: [
      {
        linkName: 'LUXVT Listing Campaigns',
        url: 'https://elite.luxvt.com/luxvt-listing-campaign/',
        description: 'include a property video created from your existing photos, with animated aerial footage from Google Earth.'
      },
      {
        linkName: 'Luxury Photography & Video Directory',
        url: 'https://elite.luxvt.com/the-luxvt-luxury-real-estate-photography-and-video-directory/',
        description: 'to help you find a great pro in your area.'
      },
      {
        linkName: 'Animoto',
        url: 'https://animoto.com/',
        description: 'is a great tool for animating and titling your existing photos and videos.'
      }
    ]
  },
  {
    name: '3D Tours',
    booleanValueMap: 'amazingInteriors',
    booleanValueTitle: 'Amazing Interiors',
    booleanValueDescription: () => <>With <span>Amazing Interiors</span>, you can capture buyers attention with an immersive <span>3D Tour</span>.</>,
    type: VISUALS,
    required: [B1, S2, B2, L3, S3, B3],
    optional: [L1, S1, L2],
    img: threeDTour,
    description: '',
    notes: [
      'Also known as 3D Virtual or 3D VR, these let users take immersive self guided tours through a property using any device.',
      'A very effective solution for limited showing opportunities.',
      'Many Real Estate Photographers can also shoot 3D tours for you in the same session as your regular property photography.'
    ],
    resources: [
      {
        linkName: 'Luxury Photography & Video Directory',
        url: 'https://elite.luxvt.com/the-luxvt-luxury-real-estate-photography-and-video-directory/',
        description: 'to help you find a great pro in your area.'
      },
      {
        linkName: 'A REALTOR’S GUIDE TO VIRTUAL TOURS',
        url: 'https://www.proplogix.com/blog/a-realtors-guide-to-virtual-tours/'
      },
      {
        linkName: 'Innovative Virtual Tour Companies',
        url: 'https://propertyspark.com/top-11-most-innovative-real-estate-virtual-tour-companies/'
      },
      {
        linkName: 'Matterport',
        url: 'https://go.matterport.com/REMAX.html?utm_source=REMAX&utm_medium=Co-Marketing&utm_term=Market-Place&utm_content=Landing_page&utm_campaign=CM-2020-US-RRE-REMAX '
      }
    ]
  },
  {
    name: 'Home Staging',
    booleanValueMap: 'isFurnished',
    booleanValueTitle: 'Not Furnised',
    booleanValueDescription: () => <>Since you chose <span>Not Furnished</span>, I suggest <span>Home Staging or Virtual Staging</span> to inspire buyers’ imagination.</>,
    type: VISUALS,
    required: [B3],
    optional: [S1, B1, S2, B2, L3, S3],
    img: homeStaging,
    description: '',
    notes: [
      'If your property is not furnished at the time of listing, you may want to consider professional staging to improve the interior appeal.',
      'Although once a requirement for luxury marketing, Home Staging can be quite expensive and may have limited benefit if your live showings are limited.',
      'Consider digital “Virtual Staging” options as a cost effective alternative to make your unfurnished listings appear professionally decorated.'
    ],
    resources: [
      {
        linkName: "The Real Estate Agent's Guide to Home Staging",
        url: 'https://www.bostonrealestateclass.com/posts/the-real-estate-agents-guide-to-home-staging/'
      },
      {
        linkName: 'The Ultimate Marketing Guide for Selling Luxury Homes',
        url: 'https://elite.luxvt.com/ultimate-marketing-guide-for-selling-luxury-homes/'
      }
    ]
  },
  {
    name: 'Drone/Aerial Photo & Video',
    type: VISUALS,
    required: [L2, S2, L3, S3, B3],
    optional: [L1, S1, B1, B2],
    img: droneAerial,
    description: '',
    booleanValueMap: 'acreage',
    booleanValueTitle: 'Acreage',
    booleanValueDescription: () => <>Since you selected <span>Acreage</span>, I recommend <span>Drone Aerial Photo &amp; Video</span> to showcase the scope of the property.</>,
    notes: [
      'An impressive way to show off exterior features and grounds.',
      'May be subject to local ordinances or HOA regulations, make sure to hire a qualified professional who knows the ropes.'
    ],
    resources: [
      {
        linkName: 'Luxury Photography & Video Directory',
        url: 'https://elite.luxvt.com/the-luxvt-luxury-real-estate-photography-and-video-directory/',
        description: 'to help you find a great pro in your area.'
      },
      {
        linkName: 'The Real Estate Agent’s Guide to using Drones for Real Estate Marketing',
        url: 'https://agentredefined.com/real-estate-agents-guide-using-drones-real-estate-listings-marketing/'
      }
    ]
  },
  {
    name: 'Day to Dusk Photo Editing',
    booleanValueMap: 'greatViews',
    booleanValueTitle: 'Great Views',
    booleanValueDescription: () => <>For properties with <span>Great Views</span>, you might try <span>Day To Dusk Photo Editing</span> to bring out the magic.</>,
    type: VISUALS,
    required: [S1, B1, S2, B2, S3, B3],
    optional: [L1, L2, L3],
    img: dayToDusk,
    description: '',
    notes: [
      'The rich purples, blues and oranges of dusk are an elegant backdrop for your luxury home photos.',
      'Instead of paying extra for “twilight” or “dusk” hour photography sessions, you may be able to get similar or even better results for less through photo editing.',
      'Your photographer may offer this service for a fee, or you can use one of many online services'
    ],
    resources: [
      {
        linkName: 'BoxBrownie.com - DAY TO DUSK PHOTO EDITING',
        url: 'https://www.boxbrownie.com/remax'
      },
      {
        linkName: 'Photoup',
        url: 'https://www.photoup.net/real-estate-photo-editing-services/'
      }
    ]
  },
  {
    name: 'Virtual Staging & Virtual Renovation',
    booleanValueMap: 'fixerOpportunity',
    booleanValueTitle: 'Fixer Opportunity',
    booleanValueDescription: () => <>Since you selected <span>Fixer Opportunity</span>, you might try <span>Virtual Renovation</span> to show buyers the possibilities.</>,
    type: VISUALS,
    required: [L3, S3, B3],
    optional: [S1, B1, S2, B2],
    img: virtualStaging,
    description: '',
    notes: [
      'If your property is unfurnished at the time of listing, Virtual Staging is a cost effective method to make your pics and videos come to life.',
      'Professional 3D artists will add furnishings in a style that complements the home.',
      'Virtual Renovation goes even further to inspire the imagination in properties that may need TLC.',
      'Your Real Estate photographer may offer these additional services'
    ],
    resources: [
      {
        linkName: 'Luxury Photography & Video Directory',
        url: 'https://elite.luxvt.com/the-luxvt-luxury-real-estate-photography-and-video-directory/',
        description: 'to help you find a great pro in your area.'
      },
      {
        linkName: 'BoxBrownie.com - Virtual Staging & Virtual Renovation',
        url: 'https://www.boxbrownie.com/remax'
      }
    ]
  },
  {
    name: 'Illustrated Floor Plans',
    booleanValueMap: 'newConstruction',
    booleanValueTitle: 'New Construction',
    booleanValueDescription: () => <>Since your property is <span>New Construction</span>, you may want to consider <span>Illustrated Floor Plans</span> to help buyers visualize the property.</>,
    type: VISUALS,
    required: [],
    optional: [S2, B2, L3, S3, B3],
    img: floorPlan,
    description: '',
    notes: [
      'Illustrated Floor Plans inspire the buyers imagination - and impress sellers, too.',
      "They’re a great way to minimize the need for on-site showings.",
      'You can book an inexpensive measurement through companies like Home Depot, then hire a service online to make the floor plans beautiful.'
    ],
    resources: [
      {
        linkName: 'Home Depot Measurement Service',
        url: 'https://www.homedepotmeasures.com/'
      },
      {
        linkName: 'BoxBrownie.com Floor Plans',
        url: 'https://www.boxbrownie.com/remax'
      },
      {
        linkName: 'Floor Plan Visuals',
        url: 'https://www.floorplanvisuals.com/'
      }
    ]
  },
  {
    name: 'Luxury Advertising',
    type: ADVERTISING,
    required: [L1, S1, B1, L2, S2, B2, L3, S3, B3],
    optional: [],
    img: luxAdvertising,
    description: '',
    notes: [
      'Target high-wealth audiences through prestige publications and brands.',
      'Sellers are thrilled to have exposure through elite media brands like Wall Street Journal, Mansion Global, DuPont Registry, and The RE/MAX Collection Magazine.',
      'Digital advertising can deliver greater reach and more bang for your buck.'
    ],
    resources: [
      {
        linkName: 'LUXVT Listing Campaigns',
        url: 'https://elite.luxvt.com/luxvt-listing-campaign/',
        description: 'includes placements in top luxury media.'
      },
      // {
      //   linkName: 'Wall Street Journal Real Estate',
      //   url: 'https://classifieds.wsj.com/advertise/real-estate-residential/'
      // },
      // {
      //   linkName: 'DuPont Registry',
      //   url: 'http://dupontregistry.com'
      // },
      // {
      //   linkName: 'Mansion Global',
      //   url: 'http://mansionglobal.com'
      // },
      // {
      //   linkName: 'Unique Homes',
      //   url: 'https://www.uniquehomes.com/'
      // },
      // {
      //   linkName: 'James Edition',
      //   url: 'https://www.jamesedition.com/'
      // },
      // {
      //   linkName: 'UPMKT',
      //   url: 'https://www.upmkt.us/'
      // },
      // remax mods
      // {
      //   linkName: 'The RE/MAX Collection Website',
      //   url: 'https://www.remax.com/luxury'
      // },
      {
        linkName: 'The RE/MAX Collection Website Homepage Photo Submission Guidelines',
        url: 'https://www.remax.net/content/1150'
      },
      {
        linkName: 'Advertising in The RE/MAX Collection Magazine',
        url: 'https://remaxcollectionmarketing.com/'
      },
      {
        linkName: 'Advertising in the Wall Street Journal',
        url: 'https://www.remax.net/content/929'
      },
      {
        linkName: 'Advertising in Unique Homes',
        url: 'https://www.remax.net/content/921'
      },
      {
        linkName: 'Advertising in duPont Registry',
        url: 'https://www.remax.net/content/890'
      }
    ]
  },
  {
    name: 'Global Advertising',
    type: ADVERTISING,
    required: [L1, S1, B1, L2, S2, B2, L3, S3, B3],
    optional: [],
    img: globalAdvertising,
    description: '',
    notes: [
      'Luxury homeowners have come to expect some level of International marketing from their Realtors.',
      'Some marketing suites include exposure in dozens of countries effortlessly.',
      'It’s a smart idea to prepare a report for your sellers to show them the global exposure you’re providing.'
    ],
    resources: [
      {
        linkName: 'LUXVT Listing Campaigns',
        url: 'https://elite.luxvt.com/luxvt-listing-campaign/',
        description: 'include 100+ international ads, plus seller reporting.'
      },
      {
        linkName: 'Juwai',
        url: 'https://list.juwai.com/',
        description: 'the most popular site for Chinese investors.'
      },
      // {
      //   linkName: 'Mansion Global from Dow Jones',
      //   url: 'https://www.mansionglobal.com/articles/welcome-to-mansion-global-1550',
      //   description: 'now offers multilingual sites.'
      // },
      {
        linkName: 'Are You Losing Listings Because You’re Not Global?',
        url: 'https://elite.luxvt.com/are-you-losing-listings-because-youre-not-global/'
      }
    ]
  },
  {
    name: 'Social Media Promotion',
    booleanValueMap: 'boostBrandVisibility',
    booleanValueTitle: 'Boost Brand Visibility',
    booleanValueDescription: () => <>Because you chose <span>Boost My Brand</span> as an objective, I suggest a strong <span>Social Media Promotion</span> to build mindshare in your sphere.</>,
    type: ADVERTISING,
    required: [L1, S1, B1, L2, S2, B2, L3, S3, B3],
    optional: [],
    img: socialMediaPromotion,
    description: '',
    notes: [
      "For luxury marketing, you want to show your sellers you’re going above and beyond. That may mean doing more than just posting to your own social accounts and pages.",
      'Facebook, YouTube and Instagram are highly visual platforms that are a great showcase for professional photos and videos.',
      'Paid advertising on the Facebook network can give your listing a boost, although new targeting limitations for real estate can be tricky to navigate.',
      "Leverage the seller’s social networks by asking them to share the property videos and marketing media that you create.  It increases your brand visibility while hitting a target buyer audience!",
      'Neighborhood Facebook Groups or Nextdoor pages are good place to get hyperlocal visibility',
      'Promote your events like Virtual Open Houses, and make sure to broadcast your Sold success stories!'
    ],
    resources: [
      {
        linkName: 'LUXVT Listing Campaigns',
        url: 'https://elite.luxvt.com/luxvt-listing-campaign/',
        description: 'include Facebook/Instagram video ads, automated YouTube video posting, and more.'
      },
      {
        linkName: '10 Power Tips For Using Social Media To Sell A House',
        url: 'https://www.easyagentpro.com/blog/social-media-to-sell-a-house/'
      },
      {
        linkName: 'Tips for Real Estate Agents: How to Promote Your Listings on Nextdoor with Posts',
        url: 'https://business.nextdoor.com/local/resources/tips-for-real-estate-agents-how-to-promote-your-listings-on-nextdoor-with-posts'
      },
      {
        linkName: 'The RE/MAX Collection on Photofy',
        url: 'https://signup.photofy.com/remax-invite'
      },
      {
        linkName: 'Request to Join The RE/MAX Collection Facebook Group',
        url: 'https://www.facebook.com/groups/TheREMAXCollectionGroup',
        description: 'Join The RE/MAX Collection Facebook Group: The RE/MAX Collection Facebook Group are global professionals who want to build a community of like-minded individuals to share best practices in luxury, provide relevant content, new technology, share stories and contribute through positive communication and are passionate about the brand. The purpose of this group is to share the power of our luxury network, expand our network, increase The RE/MAX Collection share of voice by offering ideas, and to help each other grow.',
        postDescription: 'The RE/MAX Collection Facebook Group does not serve as a listing platform.'
      },
      {
        linkName: 'The RE/MAX Collection Facebook Group - Photo Submission Guidelines',
        url: 'https://www.remax.net/content/2816'
      }
    ]
  },
  {
    name: 'Open House (Virtual or Live)',
    type: ADVERTISING,
    required: [B1, B2, B3],
    optional: [L1, S1, L2, S2, L3, S3],
    img: openHouse,
    description: '',
    notes: [
      'An Open House event, whether virtual or live, is an effective way to show the property to multiple buyers without coordinating private showings.',
      'Virtual Open Houses are less intrusive for the seller, and also also a great way to connect with a local audience, build your brand visibility and network with affluent luxury buyers.',
      'Live Open Houses for luxury properties are a good time to showcase your marketing chops - make sure to bring media kits, presentation pieces and have your videos showing on the tvs.'
    ],
    resources: [
      {
        linkName: '7 Steps to a Successful Virtual Open House',
        url: 'https://business.nextdoor.com/local/resources/how-to-host-a-virtual-open-house'
      },
      {
        linkName: 'Virtual Open House on Facebook Live – 3 Easy Steps',
        url: 'https://elite.luxvt.com/virtual-open-house-on-facebook-live-3-easy-steps/'
      },
      {
        linkName: 'FREE Virtual Open House Tools with your next LUXVT listing',
        url: 'https://elite.luxvt.com/free-virtual-open-house-promo-tools/'
      }
    ]
  },
  {
    name: 'Listing Syndication',
    type: ADVERTISING,
    required: [L1, S1, B1, L2, S2, B2, L3, S3, B3],
    optional: [],
    img: listingSyndication,
    description: '',
    notes: [
      'Once that you have confirmed that your US listing qualifies to be in The RE/MAX Collection and it is entered in the MLS, your listing will automatically syndicate to these websites: <a href="http://www.remax.com/luxury" target="_blank" rel="noopener noreferrer">http://www.remax.com/luxury</a>, <a href="http://www.wsj.com" target="_blank" rel="noopener noreferrer">https://www.wsj.com/</a>,  <a href="https://global.remax.com/" target="_blank" rel="noopener noreferrer">https://global.remax.com/</a>,  and <a href="https://www.remax.com" target="_blank" rel="noopener noreferrer">https://www.remax.com/</a>. To find, search by zip code. If the US listing is over $1 million, it will also syndicate to <a href="https://www.mansionglobal.com" target="_blank" rel="noopener noreferrer">https://www.mansionglobal.com</a>.',
      'Key benefits to listing syndication platforms are that they typically have lots of traffic - and therefore increase exposure and shorten selling time.',
      'Listing syndications also provide more comprehensive, in-depth and up to date information about a property listing, allowing buyers, sellers, and agents to make a confident, informed decision.'
    ],
    resources: [
      {
        linkName: 'How to Maximize Real Estate Listing Syndication',
        url: 'https://www.thebalancesmb.com/what-sites-are-best-for-real-estate-listing-syndication-2867127'
      },
      {
        linkName: 'The RE/MAX Collection US Threshold PDF',
        url: 'https://www.remax.net/content/2550',
        description: 'Click here for US price qualifications to be listed in The RE/MAX Collection.'
      }
    ]
  },
  {
    name: 'Local Advertising',
    type: ADVERTISING,
    required: [B1, B2, L3, S3, B3],
    optional: [S1, S2],
    img: localAdvertising,
    description: '',
    notes: [
      'Local advertising for Luxury Real Estate traditionally involved magazines, newspapers, mailers, and signage.',
      'It now encompasses modern digital forms of advertising as well - such as targeted Google and Facebook ads.'
    ],
    resources: []
  },
  {
    name: 'Postcards/Mailers',
    booleanValueMap: 'eliteNeighborhood',
    booleanValueTitle: 'Elite Neighborhood',
    booleanValueDescription: () => <>Since your property is in an <span>Elite Neighborhood</span>, you may want to build brand awareness with  a <span>Postcard/Mailer</span>.</>,
    type: ADVERTISING,
    required: [B1, B2, B3],
    optional: [],
    img: postcardMailer,
    description: '',
    notes: [
      "Direct mail is a powerful component of luxury marketing.",
      'Consistency is key, so make mailing a part of your overall plan rather than an occasional listing promo.',
      'For luxury listings, go for larger size pieces with upgraded paper stock, coatings and professional design.'
    ],
    resources: [
      {
        linkName: 'LUXVT Just Listed and Just Sold Postcards',
        url: 'https://elite.luxvt.com/just-sold-listed-postcards/'
      },
      {
        linkName: 'THE ULTIMATE GUIDE TO GETTING LUXURY HOME LISTINGS',
        url: 'http://www.ladreams.com/ultimate-guide-to-getting-luxury-home-listings/'
      },
      // {
      //   linkName: 'Design Inspiration',
      //   url: 'https://www.google.com/search?q=luxury+real+estate+mailers&rlz=1C1CHBF_enUS863US863&sxsrf=ALeKk03yZPkBB42aBQ2J1NjBi5GrlcApYg:1608151836299&source=lnms&tbm=isch&sa=X&ved=2ahUKEwiKycm3sNPtAhVCLn0KHVnvACcQ_AUoAXoECAQQAw&biw=1920&bih=947',
      //   description: 'from around the web.'
      // },
      {
        linkName: 'Xpressdocs',
        url: 'https://www.xpressdocs.com/remax/'
      },
      {
        linkName: 'Deluxe',
        url: 'https://www.impactorder.com/site/browse'
      },
      {
        linkName: 'REAL Marketing',
        url: 'https://remaxcollectionmarketing.com/'
      },
      {
        linkName: 'The RE/MAX Collection on Megaphone',
        url: 'https://megaphone.remax.com/'
      }
    ]
  },
  {
    name: '<span style="display: inline-block">Brochures/</span><span style="display: inline-block">Catalogs</span>',
    type: MATERIALS,
    required: [S1, B1, S2, B2, L3, S3, B3],
    optional: [L1, L2],
    img: brochuresCatalogs,
    description: '',
    notes: [
      'Brochures and catalogs are a mainstay of luxury listing marketing and you should plan on having at least one elegant print piece to hand out.',
      "Don’t be afraid to use a flyer box to distribute even high end brochures - it's a great way to get noticed by neighbors.",
      'Longer format catalogs are an impressive and stylish option.'
    ],
    resources: [
      {
        linkName: 'Breakthrough Broker',
        url: 'http://breakthroughbroker.com'
      },
      {
        linkName: 'LUXVT Print Ready Listing Brochures',
        url: 'https://elite.luxvt.com/print-ready-listing-brochure/'
      },
      {
        linkName: '18 Stunning Luxury Brochure Design Examples for Your Inspiration',
        url: 'http://www.momentum18.com/blog/luxury-brochure-design-inspiration/'
      },
      {
        linkName: 'Xpressdocs',
        url: 'https://www.xpressdocs.com/remax/'
      },
      {
       linkName: 'Deluxe',
       url: 'https://www.impactorder.com/site/browse ' 
      },
      {
        linkName: 'REAL Marketing',
        url: 'https://remaxcollectionmarketing.com/'
      },
      {
        linkName: 'The RE/MAX Collection on Megaphone',
        url: 'https://megaphone.remax.com/'
      },
      {
        linkName: 'The RE/MAX Collection Consumer Brochure',
        url: 'https://shop.remax.net/products/the-re-max-collection-client-benefits-brochure-5-pk?_pos=1&_sid=d73b4a9bf&_ss=r'
      },
      {
        linkName: 'The RE/MAX Collection Brochure',
        url: 'https://shop.remax.net/products/the-re-max-collection-brochure-5-pk?_pos=2&_sid=d73b4a9bf&_ss=r'
      }
    ]
  },
  {
    name: 'Hardcover Books/Coffee Table Books',
    type: MATERIALS,
    required: [S1, S2, S3, B3],
    optional: [B1, B2, L3],
    img: hardcoverBooks,
    description: '',
    notes: [
      'Large format hardcover books are a showstopper that attract attention and “wow” both sellers and buyers.',
      'Low quantities are fine to keep on hand for showings or give to high value prospects.',
      'Give them as a memorable gifts to the buyer and seller after the transaction'
    ],
    resources: [
      {
        linkName: 'Mixbook Photo Books',
        url: 'http://mixbook.com'
      },
      {
        linkName: 'Snapfish Photo Books',
        url: 'https://www.snapfish.com/home'
      },
      {
        linkName: 'Shutterfly Photo Books',
        url: 'https://www.shutterfly.com/lp/photobooks_varA_upto20.jsp'
      },
      {
        linkName: 'REAL Marketing',
        url: 'https://remaxcollectionmarketing.com/'
      }
    ]
  },
  {
    name: 'Listing Website',
    type: MATERIALS,
    required: [L1, S1, B1, L2, S2, B2, L3, S3, B3],
    optional: [],
    img: listingWebsite,
    description: '',
    notes: [
      'A property listing website is a powerful promotional tool for agents and necessary for marketing and sharing a property online.',
      'An individual listing website helps sellers know that you are committed and dedicated to promoting and selling their property - and is a great way to win a listing.',
      'Individual Listing Websites are important resources for not just basic details of the property - such as square footage and price - but also for virtual and 3D tours, open house times, and a direct way to contact agents.',
      'Listing websites should be designed to be both mobile and desktop friendly, and easily shareable on social media and in marketing campaigns.'
    ],
    resources: [
      {
        linkName: 'LUXVT Listing Campaigns',
        url: 'https://elite.luxvt.com/luxvt-listing-campaign/',
        description: 'include a luxury-tailored Single Listing Website with custom domain.'
      },
      {
        linkName: 'RELA HQ',
        url: 'http://relahq.com',
        description: 'has some lovely Single Listing Website designs.'
      },
      {
        linkName: 'The Close discusses Single Property Websites',
        url: 'https://theclose.com/single-property-websites/'
      }
    ]
  },
  {
    name: 'Virtual/Digital Catalog',
    type: MATERIALS,
    required: [L1, S1, B1, L2, S2, B2, L3, S3, B3],
    optional: [],
    img: virtualCatalog,
    description: '',
    notes: [
      'Digital catalogs can be quickly customized to a listing and conveniently shared with potential clients via email or social media.',
      'Digital flipbooks look professional and high end when embedded on landing pages and websites.',
      'Digital catalogs are more budget friendly than printed catalogs and can go a long way in gaining you exposure as a seller agent.'
    ],
    resources: [
      {
        linkName: 'Issuu',
        url: 'http://issuu.com'
      },
      {
        linkName: 'StudeoHQ',
        url: 'https://studeoapp.com/'
      }
    ]
  },
  {
    name: 'Seller Media Kit',
    type: MATERIALS,
    required: [L1, S1, B1, L2, S2, B2, L3, S3, B3],
    optional: [],
    img: mediaKit,
    description: '',
    notes: [
      'Media kit presentations break down your advertising plan and build seller confidence in your marketing strategy for a property listing.',
      'The best media kits are customized to the seller’s listing and show examples of their home being advertised in the most elite media brands and publications for real estate.',
      'Media kits are beneficial in showing your seller the depth and sophistication of your campaign and can easily be shared as digital or printed copies.'
    ],
    resources: [
      {
        linkName: 'LUXVT Listing Campaigns',
        url: 'https://elite.luxvt.com/luxvt-listing-campaign/',
        description: 'include a 16 page custom media kit to share with sellers.'
      }
    ]
  },
  {
    name: 'Activity Report',
    booleanValueMap: 'impressSellers',
    booleanValueTitle: 'Impress Sellers',
    booleanValueDescription: () => <>Since your objective is to <span>Make Sellers Happy</span>, make sure to send plenty of <span>Activity Reports</span> to keep them informed and impressed.</>,
    type: MATERIALS,
    required: [L1, S1, B1, L2, S2, B2, L3, S3, B3],
    optional: [],
    img: activityReport,
    description: '',
    notes: [
      'An activity report demonstrates to sellers the ongoing scope and activity of your marketing campaigns.',
      'Automated reports are a great way to keep in touch with sellers and let them know you’re working hard for them.'
    ],
    resources: [
      {
        linkName: 'LUXVT Listing Campaigns',
        url: 'https://elite.luxvt.com/luxvt-listing-campaign/',
        description: 'include an automated weekly email Activity Report.'
      }
    ]
  },
  {
    name: 'Signage',
    booleanValueMap: 'curbAppeal',
    booleanValueTitle: 'Curb Appeal',
    booleanValueDescription: () => <>Because you chose <span>Curb Appeal</span>, I suggest <span>Signage</span> to boost your brand visibility and lead generation.</>,
    type: MATERIALS,
    required: [L1, S1, B1, L2, S2, B2, L3, S3, B3],
    optional: [],
    img: signage,
    description: '',
    notes: [
      'Signage is one of the most important brand building tools in luxury real estate, so maximize your opportunities.',
      'Your typical listing signage setup includes a yard sign, but can be enhanced with lead generating URLs and text codes, or even interior photos.',
      'Some affluent communities restrict signage use, so be sure to know the rules in advance.'
    ],
    resources: [
      // {
      //   linkName: 'Real Estate Yard Signs: 9 Design Trends & Insights',
      //   url: 'https://theclose.com/real-estate-yard-signs/'
      // },
      {
        linkName: 'Dee Sign',
        url: 'https://www.deesign.com/real-estate-signs/REMAX/collection/'
      },
      {
        linkName: 'Lowen Sign',
        url: 'https://www.lowensign.com/realestatesigns/remax/'
      },
      {
        linkName: 'Build A Sign',
        url: 'https://www.buildasign.com/store/Remax/the-re-max-collection-yard-signs'
      }
    ]
  },
];

export const tips = [
  // Binary value only applies to unfurnished value, hence "Unfurnished" as fieldLabel
  {
    fieldValue: 'isFurnished',
    fieldLabel: 'Unfurnished',
    name: 'Home Staging or Virtual Staging',
    link: 'http://google.com',
    icon: props => <KingBedOutlinedIcon {...props} />
  },
  {
    fieldValue: 'waterfront',
    fieldLabel: 'Waterfront',
    name: 'Twilight Photography or Day To Dusk',
    link: 'http://google.com',
    icon: props => <WavesIcon {...props} />
  },
  {
    fieldValue: 'acreage',
    fieldLabel: 'Acreage',
    name: 'Drone or Aerial Photos and Video',
    link: 'http://google.com',
    icon: props => <LandscapeIcon {...props} />
  },
  {
    fieldValue: 'greatViews',
    fieldLabel: 'Great Views',
    name: 'HDR Photography',
    link: 'http://google.com',
    icon: props => <HdrOnIcon {...props} />
  },
  {
    fieldValue: 'curbAppeal',
    fieldLabel: 'Curb Appeal',
    name: 'Enhance Signage',
    link: 'http://google.com',
    icon: props => <ApartmentIcon {...props} />
  },
  {
    fieldValue: 'amazingInteriors',
    fieldLabel: 'Amazing Interiors',
    name: '3D Tour',
    link: 'http://google.com',
    icon: props => <BorderAllOutlinedIcon {...props} />
  },
  {
    fieldValue: 'eliteNeighborhood',
    fieldLabel: 'Elite Neigborhood',
    name: 'Postcard or Doorhanger',
    link: 'http://google.com',
    icon: props => <DirectionsWalkIcon {...props} />
  },
  {
    fieldValue: 'smartAmenities',
    fieldLabel: 'Smart Amenities',
    name: 'Video',
    link: 'http://google.com',
    icon: props => <EmojiObjectsIcon {...props} />
  },
  {
    fieldValue: 'fixerOpportunity',
    fieldLabel: 'Fixer Opportunity',
    name: 'Virtual Renovation',
    link: 'http://google.com',
    icon: props => <BuildIcon {...props} />
  },
  {
    fieldValue: 'newConstruction',
    fieldLabel: 'New Construction',
    name: '3D Renderings',
    link: 'http://google.com',
    icon: props => <BorderClearIcon {...props} />
  },
  {
    fieldValue: 'manageCostAndRisk',
    fieldLabel: 'Manage Cost and Risk',
    name: 'Pay At Close',
    link: 'http://google.com',
    icon: props => <MonetizationOnIcon {...props} />
  },
  {
    fieldValue: 'impressSellers',
    fieldLabel: 'Impress My Seller A Lot',
    name: 'Seller Media Kit & Activity Reports',
    link: 'http://google.com',
    icon: props => <EmojiEmotionsIcon {...props} />
  },
  {
    fieldValue: 'luxuryFactor',
    fieldLabel: 'Luxury Factor: OMG!',
    name: 'Lifestyle Video',
    link: 'http://google.com',
    icon: props => <VideocamIcon {...props} />
  }
];
