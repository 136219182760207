import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import {  CheckboxField } from '../../FormFields';
import useStyles from './styles';

const highlightsOptions = [
  {
    value: 'waterfront',
    label: 'Waterfront'
  },
  {
    value: 'acreage',
    label: 'Acreage'
  },
  {
    value: 'greatViews',
    label: 'Great Views'
  },
  {
    value: 'curbAppeal',
    label: 'Curb Appeal'
  },
  {
    value: 'amazingInteriors',
    label: 'Amazing Interiors'
  },
  {
    value: 'eliteNeighborhood',
    label: 'Elite Neighborhood'
  },
  {
    value: 'smartAmenities',
    label: 'Smart Amenities'
  },
  {
    value: 'fixerOpportunity',
    label: 'Fixer Opportunity'
  },
  {
    value: 'newConstruction',
    label: 'New Construction'
  }
];

const FormStep2 = ({
  formField: {
    propertyHighlights
  }
}) => {

  const classes = useStyles();
  return (
    <>
      <Typography variant="h6" gutterBottom className={classes.formTitle}>
        Property Details
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <CheckboxField
            name={propertyHighlights.name}
            label={propertyHighlights.label}
            data={highlightsOptions}
            fullWidth
          />
        </Grid>
      </Grid>
    </>
  );
};

export default FormStep2;
