import React from 'react';
import { Link, Typography } from '@material-ui/core/';

const Footer = () => (
  <Typography variant="body2" color="textSecondary" align="center">
    Copyright &copy;
    <Link color="inherit" href="http://elite.luxvt.com">
      LUXVT&nbsp;
    </Link>
    {new Date().getFullYear()}
  </Typography>
);

export default Footer;
