import React, { useState } from 'react';
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  CircularProgress
} from '@material-ui/core';
import { Formik, Form } from 'formik';

import { useHistory } from 'react-router-dom';

import FormStep1 from './Forms/FormStep1';
import FormStep2 from './Forms/FormStep2';
import FormStep3 from './Forms/FormStep3';

import Loading from '../Loading';

import validationSchema from './FormModel/validationSchema';
import formModel from './FormModel/formModel';
import formInitialValues from './FormModel/formInitialValues';

import useStyles from './styles';

const steps = ['Step 1', 'Step 2', 'Step 3'];
const { formId, formField } = formModel;

const _renderStepContent = step => {
  switch(step) {
    case 0:
      return <FormStep1 formField={formField} />;
    case 1:
      return <FormStep2 formField={formField} />;
    case 2: 
      return <FormStep3 formField={formField} />;
    default: 
      return <div>Not Found</div>;
  }
};

const MainPage = () => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const currentValidationSchema = validationSchema[activeStep];
  const isLastStep = activeStep === steps.length - 1;
  const isComplete = activeStep === steps.length;

  const history = useHistory();

  const _sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

  const _submitForm = async (values, actions) => {
    const {
      streetAddress,
      country,
      zipCode,
      price,
      isFurnished,
      propertyHighlights,
      agentObjectives
    } = values;
    await _sleep(3000);
    actions.setSubmitting(false);

    const highlightsString = propertyHighlights.map(item => `&${item}=true`).join('');
    const objectivesString = agentObjectives.map(item => `&${item}=true`).join('');

    setActiveStep(activeStep + 1);
    history.push(`/plan?streetAddress=${streetAddress.trim()}&country=${country.trim()}&zipCode=${zipCode.trim()}&price=${price.trim()}&isFurnished=${isFurnished}${highlightsString}${objectivesString}`);
  };

  const _handleSubmit = (values, actions) => {
    if (isLastStep) {
      _submitForm(values, actions);
    } else {
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  };

  const _handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  return (
    <>
      <Typography component="h1" variant="h4" align="center">
        Listing Planner
      </Typography>
      {
        activeStep < steps.length && (
          <Stepper activeStep={activeStep} className={classes.stepper}>
            {steps.map(label => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        )
      }
      <>
        <Formik
          initialValues={formInitialValues}
          validationSchema={currentValidationSchema}
          onSubmit={_handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form id={formId}>
              { isSubmitting && <Loading /> }
              {_renderStepContent(activeStep)}
              {
                !isComplete && (
                  <div className={classes.buttons}>
                    {activeStep !== 0 && (
                      <Button onClick={_handleBack} className={classes.button}>
                        Back
                      </Button>
                    )}
                    <div className={classes.wrapper}>
                      <Button
                        disabled={isSubmitting}
                        type="submit"
                        variant="contained"
                        color="primary"
                        className={classes.button}
                      >
                        {isLastStep ? 'Submit' : 'Next'}
                      </Button>
                      {isSubmitting && (
                        <CircularProgress
                          size={24}
                          className={classes.buttonProgress}
                        />
                      )}
                    </div>
                  </div>
                )
              }
            </Form>
          )}
        </Formik>
      </>
    </>
  );
};

export default MainPage;
