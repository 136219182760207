import React from 'react';
import Typography from '@material-ui/core/Typography';
import ReactLoading from 'react-loading';
import { motion, AnimatePresence } from 'framer-motion';
import useStyles from './styles';

const Loading = () => {
  const classes = useStyles();

  return (
    <AnimatePresence>
      <motion.div
        key="modal"
        className={classes.loading}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <ReactLoading key="loader" type="bars" color="white" height={128} width={128} />
        <Typography key="typography" variant="h5" className={classes.loadingText}>Generating your plan...</Typography>
      </motion.div>
    </AnimatePresence>
  );
};

export default Loading;