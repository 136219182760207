const model =  {
  formId: 'listingPlannerForm',
  formField: {
    streetAddress: {
      name: 'streetAddress',
      label: 'Listing Street Address',
      requiredErrorMsg: 'Listing Street Address is required'
    },
    country: {
      name: 'country',
      label: 'Country'
    },
    zipCode: {
      name: 'zipCode',
      label: 'ZIP/Postal Code',
      requiredErrorMsg: 'Zip/Postal Code is required'
    },
    price: {
      name: 'price',
      label: 'Listing Price',
      requiredErrorMsg: 'Listing Price is required',
      invalidErrorMsg: 'Price must be a number greater than 10,000'
    },
    bedrooms: {
      name: 'bedrooms',
      label: 'Bedrooms',
      requiredErrorMsg: 'Bedrooms is required',
      invalidErrorMsg: 'Bedrooms must be a valid number'
    },
    bathrooms: {
      name: 'bathrooms',
      label: 'Bathrooms',
      requiredErrorMsg: 'Bathrooms is required',
      invalidErrorMsg: 'Bathrooms must be a valid number'
    },
    isFurnished: {
      name: 'isFurnished',
      label: 'Currently Furnished/Unfurnished',
      requiredErrorMsg: 'This field is required'
    },
    luxuryFactor: {
      name: 'luxuryFactor',
      label: 'Luxury Factor'
    },
    sellerProfile: {
      name: 'sellerProfile',
      label: 'Seller Profile'
    },
    propertyHighlights: {
      name: 'propertyHighlights',
      label: 'Property Highlights'
    },
    agentObjectives: {
      name: 'agentObjectives',
      label: 'Agent Objectives'
    }
  }
};

export default model;
