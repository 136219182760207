import React from 'react';
import { Route } from 'react-router-dom';
import { Grid, Typography, Paper, Button } from '@material-ui/core';
import { useHistory, Link } from 'react-router-dom';
import qs from 'qs';
import Plan from '../Plan';
import Share from '../Share';
import {
  plans,
  UPPER_PRICE_TIER,
  MIDDLE_PRICE_TIER,
  LEAN,
  SATISFACTORY,
  BOOST
} from '../Plan/data';
import useStyles from './styles';

const Results = () => {
  const history = useHistory();
  const valueMap = qs.parse(history.location.search.split('?')[1]);

  const { location: { pathname, search } } = history;
  const back = `${pathname}${search.split('&plan=')[0]}`;
  const planURL = `plan${history.location.search}`;

  const {
    price,
    isFurnished,
    plan,
    waterfront,
    acreage,
    greatViews,
    curbAppeal,
    amazingInteriors,
    eliteNeighborhood,
    smartAmenities,
    fixerOpportunity,
    newConstruction,
    impressSellers,
    boostBrandVisibility,
    streetAddress
  } = valueMap;

  const booleanValueProps = {
    waterfront,
    acreage,
    greatViews,
    curbAppeal,
    amazingInteriors,
    eliteNeighborhood,
    smartAmenities,
    fixerOpportunity,
    newConstruction,
    // the true value is not furnished, hence the below weirdness
    isFurnished: isFurnished === 'false' ? 'true' : 'false',
    impressSellers,
    boostBrandVisibility
  };

  const classes = useStyles();

  const goTo = where => {
    history.push(where);
  }

  const determinePlan = name => {
    let tier = 0;
    if (price >= MIDDLE_PRICE_TIER && price < UPPER_PRICE_TIER) {
      tier = 1;
    }
    if (price >= UPPER_PRICE_TIER) {
      return plans[name][2];
    }
    return plans[name][tier];
  };

  return (
    <>
      <Route path="/" component={() => (
        <Grid container className={classes.gridContainer}>
          <Grid item xs={12}>
            <Share address={streetAddress} url={planURL}/>
              {
                !!plan && <Typography variant="body1" style={{ marginBottom: 8 }}><Link to={back} className={classes.navLink}>Back to all plans</Link></Typography>
              }
            <Typography variant="h3" className={classes.pageTitle}>
              {
                !plan && 'Your Listing Plans'
              }
              {
                plan === LEAN && (<><span>Lean Luxury</span> Marketing Plan</>)
              }
              {
                plan === SATISFACTORY && (<><span>Seller Satisfaction</span> Marketing Plan</>)
              }
              {
                plan === BOOST && (<><span>Brand Boost</span> Marketing Plan</>)
              }
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5" className={classes.address}>
              {valueMap.streetAddress}
            </Typography>
          </Grid>
          {
            !plan && (
            <>
            <Grid item className={classes.gridItem}>
              <Paper variant="outlined" className={classes.paper}>
                <Typography variant="body1" className={classes.budget}>
                  PLAN 1 - <span>Budget: $</span>
                </Typography>
                <Typography variant="h4" className={classes.planName}>
                  Lean Luxury
                </Typography>
                <Typography variant="body1" className={classes.description}>
                  Look great and get the job done cost effectively.
                </Typography>
                <div className={classes.block}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    onClick={() => goTo(`${history.location.pathname}${history.location.search}&plan=${LEAN}`)}
                  >
                    View Plan
                  </Button>
                </div>
              </Paper>
            </Grid>
            <Grid item className={classes.gridItem}>
              <Paper variant="outlined" className={classes.paper}>
                <Typography variant="body1" className={classes.budget}>
                  PLAN 2 - <span>Budget: $$</span>
                </Typography>
                <Typography variant="h4" className={classes.planName}>
                  Seller Satisfaction
                </Typography>
                <Typography variant="body1" className={classes.description}>
                  Go the extra mile to 'wow' your sellers and drive referrals.
                </Typography>
                <div className={classes.block}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    onClick={() => goTo(`${history.location.pathname}${history.location.search}&plan=${SATISFACTORY}`)}
                  >
                    View Plan
                  </Button>
                </div>
              </Paper>
            </Grid>
            <Grid item className={classes.gridItem}>
              <Paper variant="outlined" className={classes.paper}>
                <Typography variant="body1" className={classes.budget}>
                  PLAN 3 - <span>Budget: $$$</span>
                </Typography>
                <Typography variant="h4" className={classes.planName}>
                  Brand Boost
                </Typography>
                <Typography variant="body1" className={classes.description}>
                  Use this listing to drive brand visibility and as a showcase for future sellers.
                </Typography>
                <div className={classes.block}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    onClick={() => goTo(`${history.location.pathname}${history.location.search}&plan=${BOOST}`)}
                  >
                    View Plan
                  </Button>
                </div>
              </Paper>
            </Grid>
            </>
          )
        }
        {
          plan === LEAN && (
            <Plan name={determinePlan(LEAN)} {...booleanValueProps} />
          )
        }
        {
          plan === SATISFACTORY && (
            <Plan name={determinePlan(SATISFACTORY)} {...booleanValueProps} />
          )
        }
        {
          plan === BOOST && (
            <Plan name={determinePlan(BOOST)} {...booleanValueProps} />
          )
        }
        </Grid>
      )} />
    </>
  );
};

export default Results;
