import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { InputField, SelectField, NumberFormatField } from '../../FormFields';
import { countryList } from './countries';
import { useFormikContext } from 'formik';
import useStyles from './styles';

const furnishedOptions = [
  {
    value: true,
    label: 'Furnished'
  },
  {
    value: false,
    label: 'Unfurnished'
  }
];

const countryOptions = countryList.map(country => ({
  value: country.code,
  label: country.name
}));

const FormStep1 = ({
  formField: {
    streetAddress,
    country,
    zipCode,
    price,
    isFurnished
  }
}) => {
  const classes = useStyles();
  const { values } = useFormikContext();
  return (
    <>
      <Typography variant="h6" gutterBottom className={classes.formTitle}>
        Basic Information
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <InputField name={streetAddress.name} label={streetAddress.label} fullWidth />
        </Grid>
        <Grid item xs={12}>
          <SelectField
            name={country.name}
            label={country.label}
            data={countryOptions}
            fullWidth
          />
        </Grid>
        {
          (values.country === 'CA' || values.country === 'US') && (
            <Grid item xs={12}>
              <InputField name={zipCode.name} label={zipCode.label} fullWidth />
            </Grid>
          )
        }
        <Grid item xs={12}>
          <NumberFormatField name={price.name} label={price.label} fullWidth />
        </Grid>
        <Grid item xs={12}>
          <SelectField
            name={isFurnished.name}
            label={isFurnished.label}
            data={furnishedOptions}
            fullWidth
          />
        </Grid>
      </Grid>
    </>
  );
};

export default FormStep1;
