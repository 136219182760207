import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { CheckboxField } from '../../FormFields';

const objectivesOptions = [
  {
    value: 'manageCostAndRisk',
    label: 'Manage Cost And Risk'
  },
  {
    value: 'impressSellers',
    label: 'Impress My Sellers'
  },
  {
    value: 'boostBrandVisibility',
    label: 'Boost My Brand Visibility'
  }
];

const FormStep3 = ({
  formField: {
    agentObjectives
  }
}) => (
  <>
    <Typography variant="h6" gutterBottom>
      Additional Information (Optional)
    </Typography>
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <CheckboxField
          name={agentObjectives.name}
          label={agentObjectives.label}
          data={objectivesOptions}
          fullWidth
        />
      </Grid>
    </Grid>
  </>
);

export default FormStep3;
