import React from 'react';
import { Paper, CssBaseline } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import Header from '../Header';
import Footer from '../Footer';
import { theme, useStyle } from './styles';

const Layout = ({ children, wide}) => {
  const classes = useStyle(wide)();
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Header styles={classes.header} />
      <div className={classes.root}>
        <Paper className={classes.paper}>{children}</Paper>
      </div>
      <Footer />
    </ThemeProvider>
  );
};

export default Layout;
