import { makeStyles } from '@material-ui/core/styles';
export default makeStyles(theme => ({
  gridContainer: {
    [theme.breakpoints.up(928 + theme.spacing(2) * 2)]: {
      display: 'flex',
      justifyContent: 'space-between',
    }
  },
  gridItem: {
    width: '100%',
    [theme.breakpoints.up(928 + theme.spacing(2) * 2)]: {
      width: '31%'
    }
  },
  paper: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up(928 + theme.spacing(2) * 2)]: {
      marginBottom: 0
    },
    padding: theme.spacing(2),
    '& > *': {
      marginBottom: theme.spacing(2)
    }
  },
  address: {
    marginBottom: theme.spacing(2)
  },
  budget: {
    fontSize: 18, 
    '& > span': {
      color: theme.palette.success.main
    }
  },
  planName: {
    
  },
  description: {
    fontSize: 17,
    marginBottom: theme.spacing(3)
  },
  block: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  link: {
    textDecoration: 'none'
  },
  button: {
    textDecoration: 'none'
  },
  pageTitle: {
    '& > span': {
      fontWeight: 600,
    },
    [theme.breakpoints.down(850)]: {
      fontSize: 30,
    },
    [theme.breakpoints.down(750)]: {
      fontSize: 26,
    },
    [theme.breakpoints.down(690)]: {
      fontSize: 22,
    },
    [theme.breakpoints.down(620)]: {
      fontSize: 44,
    },
    [theme.breakpoints.down(457)]: {
      fontSize: 38,
    },
    [theme.breakpoints.down(405)]: {
      fontSize: 34,
    },
    [theme.breakpoints.down(383)]: {
      fontSize: 30,
    },
    [theme.breakpoints.down(332)]: {
      fontSize: 28,
    },
  },
  navLink: {
    color: theme.palette.primary.main,
    fontWeight: 700,
    transitionProperty: 'color',
    transitionTimingFunction: theme.transitions.easing.easeInOut,
    transitionDuration: theme.transitions.duration.shortest,
    marginBottom: theme.spacing(1),
    '&:hover': {
      color: theme.palette.primary[300]
    },
    [theme.breakpoints.down(480)]: {
      fontSize: 14
    },
  }
}));
