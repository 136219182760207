import React from 'react';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import isEmpty from 'lodash/fp/isEmpty';
import useStyles from './styles';

const PlanItem = props => {
  const classes = useStyles();

  return (
    <Paper className={classes.item}>
      <div className={classes.photoOuter}>
        { props.img && <img src={props.img} alt="item" />}
      </div>
      <div className={classes.accordionOuter}>
        <Accordion className={classes.accordion}>
          <AccordionSummary
            className={classes.accordionSummary}
            expandIcon={<ExpandMoreIcon style={{ height: 40, width: 40 }}/>}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <div className={classes.accordionTop}>
              <Typography variant="h4" dangerouslySetInnerHTML={{ __html: props.name }} />
              {
                props.recommended && (
                  <div className={classes.recommendedFlag}>
                    RECOMMENDED
                  </div>
                ) 
              }
              {
                props.optional && (
                  <div className={classes.optionalFlag}>
                    OPTIONAL
                  </div>
                )
              }
              { props.description && <Typography variant="body1" className={classes.itemDescription}>{props.description()}</Typography>}
            </div>
          </AccordionSummary>
          <AccordionDetails className={classes.details}>
            <div className={classes.accordionBottom}>
              {
                props.isTip && (
                  <Typography variant="h5">{props.title}</Typography>
                )
              }
              <ul>
                {
                  props.notes.map((note, index) => (
                    <Typography variant="body1"><li className={classes.itemNote} key={index} dangerouslySetInnerHTML={{ __html: note }}/></Typography>
                  ))
                }
              </ul>
              {
                !!props.resources && !isEmpty(props.resources) && (
                  <div className={classes.resourcesAndVendors}>
                    <Typography variant="h5">
                      Resources &amp; Vendors
                    </Typography>
                    {
                      props.resources.map(({ linkName, url, description, postDescription }, index) => (
                        <>
                        <Typography key={index} variant="body1" className={classes.resource}>
                          <a href={url} target="_blank" rel="noopener noreferrer">
                            {linkName}
                          </a>{ description && ` - ${description}`}
                        </Typography>
                        {
                          postDescription && (
                            <>
                              <br/>
                              <Typography key={index} variant="body1" className={classes.postDescription}>
                                <li style={{ listStylePosition: 'inside', marginTop: -20 }}>{postDescription}</li>
                              </Typography>
                            </>
                          )
                        }
                        </>
                      ))
                    }
                  </div>
                )
              }
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
    </Paper>
  )
};

export default PlanItem;
